<template lang="pug">
	.main-wrapper.paciente-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Guia Eletrônica</b>

		Dialog.dialogExecutar(header='Listar Guias' :modal='true' :visible.sync='dialogExecutar')
			.ta-center
				p As guias estão sendo processadas no estabelecimento <b>{{ currentEstabelecimento.nm_fantasia }}</b>. Deseja prosseguir?
				.ta-center.mb-4(v-if='waitingExecutar')
					ProgressSpinner.waitingExecutar-spinner(strokeWidth='6')
				Button.mb-4.p-button-success(v-else label='Validar guias' icon='jam jam-ticket' @click='executarGuias()')
		
		form(@submit.prevent='handleSubmit()')
			.ta-center.my-4(v-if='waitingForm')
				ProgressSpinner.waitingForm-spinner(strokeWidth='2')
			div(v-else)
				Panel.mb-2(header='Validar guia')
					div(v-if='results.length')
						div.mb-4(v-for='guias_paciente in guias')
							div(v-for='guia, estabelecimento  in guias_paciente')
								div Paciente:
								span(style="float:right") {{estabelecimento}}
								h2(style="float:left;margin:0") {{guia.cd_paciente.nm_pessoa_fisica}}
								DataTable(dataKey="cd_guia"
									:value='results.filter(result => result.cd_estabelecimento == guia.cd_estabelecimento.id && result.cd_paciente == guia.cd_paciente.id)')
									Column(headerStyle='width: 5%;' field='cd_guia' header='Cod.')
									Column(headerStyle='width: 80%;' field='nm_procedimento' header='Procedimento')
									Column(headerStyle='width: 10%;' field='nr_valortotal_f' header='Valor')
									Column(headerStyle='width: 8%;' field='cd_guia_situacao' header='Situação' bodyStyle='text-align:center')
										template(#body='props')
											span(v-if='props.data.cd_guia_situacao == 5') <i style='color: #64BA02; font-size: 2em;' class='jam jam-check'></i>
											span(v-if='props.data.cd_guia_situacao == 7') <i style='color: #DF1818; font-size: 2em;' class='jam jam-close'></i>
											span(v-if='props.data.cd_guia_situacao == 4') <i style='color: #F5CB11; font-size: 1em;' class='jam jam-clock'></i>
						.ta-center.mb-4
							Button.mr-2(label='Voltar' icon='jam jam-log-out' type='button' @click='$router.go(-1)')
</template>

<style lang="scss">
	.waitingForm-spinner {
		width: 60px;
		height: auto;
	}
	.waitingExecutar-spinner {
		width: 30px;
		height: auto;
	}
	.dialogExecutar {
		width: 96%;
		max-width: 400px;
	}
</style>

<script>
	import Panel from 'primevue/panel'
	import InputText from 'primevue/inputtext'
	import Button from 'primevue/button'
	import ProgressSpinner from 'primevue/progressspinner'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Checkbox from 'primevue/checkbox'
	import Dialog from 'primevue/dialog'
    import { required, numeric } from 'vuelidate/lib/validators'
	import { GuiaEletronica } from '../../middleware'

	export default {
		components: { Panel, InputText, Button, ProgressSpinner, DataTable, Column, Checkbox, Dialog },
		created () {
			let usuario = JSON.parse(localStorage.getItem('usuario'))
			this.currentEstabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento]

            GuiaEletronica.consultarGuia(parseInt(this.$route.params.id))
            .then(response => {
                this.waitingForm = false;
                if(response.status == 201 || response.status == 200) {
                    response.data.forEach(guia => {
							if( this.guias[guia.cd_paciente.id] == undefined )
								this.guias[guia.cd_paciente.id] ={}

							if( this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.nm_fantasia] == undefined )
								this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.nm_fantasia] = guia

							this.results.push({
								cd_estabelecimento: guia.cd_estabelecimento.id,
								cd_paciente: guia.cd_paciente.id,
								cd_guia: guia.id,
								cd_guia_situacao: guia.cd_guia_situacao,
								nr_valortotal: guia.nr_valortotal,
								nr_valortotal_f: this.formatPrice(guia.nr_valortotal),
								nm_procedimento: guia.guiaeletronicaitem_set[0] ? guia.guiaeletronicaitem_set[0].nm_procedimento : ''
							})
							
					})
                }
            })
		},
		data () {
			return {
				model: { id: null, hash: null },
				results: [],
				estabelecimentos: {},
				guias: {},
				selected: {},
				waitingForm: true,
				waitingExecutar: false,
				submitted: false,
				currentEstabelecimento: null,
				dialogExecutar: false
			}
		},
        validations () {
            let v = {
                model: {
                    id: { required, numeric },
                    hash: { required }
                }
            }
            
            return v
        },
		methods: {
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			reset () {
				this.model = { id: null, hash: null }
				this.results = []
				this.estabelecimentos = {}
				this.guias = {}
				this.selected = {}
				this.submitted = false
			}
		}
	}
</script>